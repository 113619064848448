.fieldErr{
    border: 1px solid #FF0000;
}
.fieldErrtxt{ 
    color: #ff0202;
}
.form-controlxx {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-page, .register-page {
    -ms-flex-align: center;
    align-items: center;
    background: #e9ecef;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;
}
.login-box, .register-box {
    /* width: 360px; */
	 width: auto;
}
.farx {  
	 margin-left:10px;
	 margin-right:15px;
}

.farxli li {  
	 margin-top:10px;
	 margin-bottom:10px;
}

.farxli li:hover {  
	 background-color: #e9ecef;
	 
}
.MuiPaper-rootX {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #000066!important;
}
.MuiTableCell-head{ font-weight:bold!important; } 
/* .MuiTablePagination-caption {
    flex-shrink: 0; 
}
.MuiTablePagination-caption::before{
    content: "\a\a\a"; 
} */

.sucMessage{ background-color: #28a745; color: #ffffff;  width: 80%;display: block;
    border-radius: 25px;  border: 2px solid #73AD21;}
.red{ color: #FF0000; }
.blue{ color: #000066; }
.green{ color: #004000; }
.orange{ color: #ff5500; }
.yellow{ color: #ffff26; }
.purple{ color: #9326ff; }
.margin{ margin: 10px; }
.marginr{ margin-right: 10px; }
.marginl{ margin-left: 10px; }
.margint{ margin-top: 10px; }
.marginb{ margin-bottom: 10px; }
.ovalItemPrice{ margin: 0 auto; background-color: #000066; color: #FFFFFF; border-radius: 5.65rem; width: 100px;
    height: 40px; text-align: center;-ms-flex-align: center; align-items: center; line-height: 2.5; font-weight: bolder;}
.ovalTotalPrice{ margin: 0 auto; background-color: #000066; color: #FFFFFF; border-radius: 5.65rem; width: 250px;
    height: 50px; text-align: center;-ms-flex-align: center; align-items: center; line-height: 3.0; font-weight: bolder;vertical-align: middle;margin-bottom: 10px;}
.btn-rounded {
    border-radius: 100px;
}

.btn-cons {
    margin-right: 5px;
    min-width: 120px;
}
.btn-lg, .btn-group-lg > .btn {
    padding-left: 28px;
    padding-right: 28px;
    line-height: 23px;
}
.btn-complete, .btn-complete:focus {
    color: #fff;
    background-color: #0052bb;
    border-color: #0052bb;
}    
/* .css-2613qy-menu {
    z-index: 999999999!important; opacity: 1;
  } */
.MuiInputLabel-outlined {
z-index: 0!important; 
}
.neocardx{ height:560px; min-width: 450px;}
.neocardx2{ min-height:270px; min-width: 450px;}
.neocardx3{ min-height:120px; min-width: 450px;}
.neocard0{ background-color: #4772a0; min-width: 337px;}
.neocard1{ background-color: #007bff; min-width: 337px;}
.neocard2{ background-color: #6610f2;  min-width: 337px;color: #ffffff;}
.neocard3{ background-color: #6f42c1;  min-width: 337px; color: #ffffff;}
.neocard4{ background-color: #e83e8c;  min-width: 337px;}
.neocard5{ background-color: #dc3545;  min-width: 337px;}
.neocard6{ background-color: #fd7e14;  min-width: 337px;}
.neocard7{ background-color: #ffc107;  min-width: 337px;}
.neocard8{ background-color: #28a745;  min-width: 337px;}
.neocard9{ background-color: #20c997;  min-width: 337px;}
.neocard10{ background-color: #17a2b8;  min-width: 337px;}
.neocard11{ background-color: #6c757d;  min-width: 337px;}
.neocard12{ background-color: #343a40;  min-width: 337px;color: #ffffff;}
.neocard13{ background-color: #0a3b70;  min-width: 337px; color: #fff;}
.neocard14{ background-color: #6c757d;  min-width: 337px;}
.neocard15{ background-color: #28a745;  min-width: 337px;}
.neocard16{ background-color: #140255;  min-width: 337px; color: #fff;}
.neocard17{ background-color: #221550;  min-width: 337px; color: #fff;}
.neoinline50 {
    flex: 0 0 50%;
    /* max-width: 50%; */
    width: 50%;
    display: inline-block;
    text-align: right;
}
.neoinline100 { 
    width: 100%;
    display: inline-block;
    text-align: right;
}
.neoTopLbll { 
    width: 80%;
    display: inline-block;
    /* text-align: left; */
}
.neoTopLblr { 
    width: 20%;
    display: inline-block;
    text-align: right;
}
.bold {
    font-weight: bold !important;
}
.neopad{ padding-left: 5px;padding-right: 5px; }
.loaderPicx{ position:absolute; z-index: 0; margin-top: 23px;margin-left: 21px;}
.neox50 {
    flex: 0 0 50%;
    /* max-width: 50%; */
    width: 50%;
    display: inline-block; 
}
.neoinline70 {
    flex: 0 0 70%;
    /* max-width: 50%; */
    width: 70%;
    display: inline-block; 
}
.neoinline40 {
    flex: 0 0 40%;
    /* max-width: 50%; */
    width: 40%;
    display: inline-block; 
}
.neoinline30 {
    flex: 0 0 30%;
    /* max-width: 50%; */
    width: 30%;
    display: inline-block; 
}
.neoinline20 {
    flex: 0 0 20%;
    /* max-width: 50%; */
    width: 20%;
    display: inline-block; 
}
.neoinline50l {
    flex: 0 0 50%;
    /* max-width: 50%; */
    width: 50%;
    /* background-color: #5383b8; */
    display: inline-block; 
    padding-left: 20px;
}
.neoforcepopup {
    /* flex: 0 0 50%; */
    min-width: 600px;
    display: block; 
}
.neoCap input {
    text-transform: uppercase;
}
 .neoxtbl table, .neoxtbl th, .neoxtbl td {
    border: 1px solid black;
    border-collapse: collapse;
  } 
  .neobgGA{ background-color: #a3caf5;padding-top:10px; }
  .neoRed{ color: #dc3545; }
  .floatr{ float: right; }
  .floatl{ float: left; }
  ul.glst {
    list-style: none;
    margin-top: 13px;
    padding-left: 0;
  }
  .neoxblck {
    /* flex: 0 0 50%;  */
    /* width: 100%; */ 
    display: block; 
    margin-top: 5px;
    min-width: 1200px;
  }
  .neoxblck ul {
    list-style: none; 
    padding-left: 0;
    text-align: right;
    margin-right: 0px;
    right:0px;
  }
  /* .floatrtxt{ text-align: right; margin-right: 2px; right: 0;} */
  .floatrtxt{ text-align: right; margin-left: 75%;  }
  .lstfilesepox { border-bottom: 1px dotted blue; height: auto; }
  .lstfilesepx{ height: 40px!important; } 
  .lstfilesepx p{ border-bottom: 1px dotted rgb(255, 72, 0);  } 
  .lstfilesepx p:hover{ border-bottom: 1px dotted rgb(255, 72, 0); background-color: #83b3f1;  } 
  .float-right{ float: right; }
  .float-left{ float: left; }
  .tabsolodiv{ margin:10px; }
  .tabsolodiv2{ margin: left 0.5rem; padding: 2rem;}

  /* ************************************************************************* */

  /* ul.list {
    list-style: none;
    margin-top: 13px;
    padding-left: 0;
}

.text-black {
    color: #000!important;
}
ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
address, blockquote, dd, dl, fieldset, form, ol, p, pre, table, ul {
    margin-bottom: 10px;
}
ul.list li {
    margin: 5px 0;
    font-weight: 500;
    padding-left: 15px;
    position: relative;
}

.pl-20 {
    padding-left: 20px!important;
}
.font-18 {
    font-size: 18px!important;
} */

.btn-theme-colored2 {
    color: #fff;
    background-color: #FF9E00;
    border-color: #FF9E00;
}

.btn-theme-colored2 {
    color: #fff;
    background-color: #FF9E00;
    border-color: #FF9E00;
}
.text-hover-theme-colored2:hover, .text-theme-colored2 {
    color: #FF9E00!important;
}

.star-rating span::before, .text-hover-theme-colored:hover, .text-theme-colored {
    color: #0052BB!important;
}

.icon-box .icon.icon-lg i {
    font-size: 48px;
    line-height: 90px;
}

.icon-box .icon i {
    line-height: 70px;
}
.icon-box i {
    display: inline-block;
    font-size: 40px;
    transition: all .3s ease;
}
.fc-timetable-wrapper .fc-toolbar .fc-button, .icon-box i {
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: width 2s;
}
.font-36 {
    font-size: 36px!important;
}
.text-white {
    color: #fff!important;
}
.fa-2x {
    font-size: 2em;
}



.icon-box .icon.icon-lg {
    height: 90px;
    width: 90px;
}

.icon-box .icon.icon-circled {
    border-radius: 50%;
}
.icon-box .icon {
    display: inline-block;
    height: 70px;
    margin-bottom: 0;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    width: 70px;
}

 
.menuAutoneo{position:absolute;z-index: 200;background-color: #83b3f1;height: 300px;overflow: scroll;} 
.item{
    border-color:#011d41;
} 
.item-highlighted{background-color: #83b3f1;font-weight: bold;}
.megamenu li{margin-left: 40px;}

/* .content-wrapper{margin-left: 0px!important;} */

/*/////////////////////////////////////////////////////////////////////////////////////////////////////  */

.neosavehover{width: auto;} 
.neosavehover:hover{
	height: 90px;
    margin-bottom: 0;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    width: 90px;
	background-color: #83b3f1; padding:5px;
} 

/*zendesk mini form */
.MuiBox-root-5 {
    padding: 0px!important; 
    padding: 24px; 
}
@media only screen and (max-width: 768px) {
    .MuiBox-root-5 {
        padding: 1px;
    }
}

.leftxt { 
     text-align: left; margin-left: 0px;
  }
.lstfileblck { 
    background-color: #eef1f7;  
    /* background-color: #bcd7fc;   */
  }

.lstfilesep {
    border-bottom: 1px dotted blue; height: auto;
  }
.lstfilesep:hover {
    border-bottom: 1px dotted blue;
    background-color: #83b3f1; height: auto; 
  }
  .neospx .MuiListItemAvatar-root00{position:relative;display: inline-block;}
  .neospx .MuiListItemText-root000 {
    /* flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px; */
    position:relative;display: inline-block;
}
.neospx .MuiListItemSecondaryAction-root00{position:relative;display: inline-block;}
.lstfilesep .flexydiv {
    position:relative;display: inline-block; height: auto; width: auto;
    /* min-width: 50%; */
  }

  .neospx .MuiListItemAvatar-root00{position:relative;display: inline-block;}
  .neospx .MuiListItemText-root00 { 
    position:relative;display: inline-block; margin: 4px; flex: none;
} 

@media only screen and (min-width: 768px) {
   .neospx .MuiListItemAvatar-root{position:relative;display: inline-block;}
  .neospx .MuiListItemText-root { 
        position:relative;display: inline-block; margin: 4px; flex: none;
    } 
}

.neoxacord .MuiAccordionDetails-root {
    /* display: flex; */
    display: block;
    padding: 8px 16px 16px;
}

.neoxacord .MuiAccordion-rounded {
    border-radius: 0;
    border-top: 1px solid rgb(134, 134, 141);
    /* border-top: 2px solid blue; */
}
 
.neoxacord .MuiAccordion-root000 {
    position: relative;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.iamneozend{margin-right: 0px;
    margin-left: 0px;} 
.iamneozend .content-wrapper>.content {
    padding: 0px;
}
.iamneozend .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0px;
}
.iamneozend .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
}

@media only screen and (max-width: 768px) {
     
}

.card {
    margin-right: 0px;
    margin-left: 0px;
}
.MuiAppBar-colorDefault{margin-right: -15px;
    margin-left: 0px;}
.MuiAppBar-rootXYXYX{
    margin-right: -15px;
    margin-left: -15px;
}
.MuiTabs-root{
    margin-right: 0px;
    margin-left: 0px;
}
.MuiTabs-rootX{
    margin-right: -15px;
    margin-left: -15px;
}
.iamneombz2{margin-right: 10px;
    margin-left: 1px;}
.containerx{margin-right: -14px;
    margin-left: -5px;}    
 
.neoxacord .MuiAccordionDetails-root {
    /* display: flex; */
    display: block;
    padding: 8px 16px 16px;
    margin-right: 0px;
    margin-left: 0px;
}
.neoxacord .MuiAccordionDetails-root .container{margin-right: -15px;
    margin-left: -25px; padding-right: 0px;}

    .neoxacord .MuiAccordionDetails-root .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 110%;
    }

.containerxgf{margin-right: -19px;
        margin-left: 5px;}   
        
.iamneombzin{
            margin-top: -24px;
            width: 103.5%;
            margin-left: -23px;
            margin-right: -2px;
        }        
.iamneombzin .MuiTabs-root {
            display: flex;
            overflow: hidden;
            min-height: 28px;
            -webkit-overflow-scrolling: touch;
            max-height: 48px;  
        }

@media only screen and (max-width: 768px) {
    .iamneozend{margin-right: -1.5px;
        margin-left: -17.5px;} 
    .iamneozend .content-wrapper>.content {
        padding: 0px;
    }
    .iamneozend .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0px;
    }
    .iamneozend .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -7.5px;
        margin-left: -7.5px;
    }
    




    .card {
        margin-right: 0px;
        margin-left: 0px;
    }
    .MuiAppBar-colorDefault{margin-right: 0px;
        margin-left: 0px;}
     
    .MuiTabs-root{
        margin-right: 0px;
        margin-left: 0px;
    }
    .iamneombz2{margin-right: 0px;
        margin-left: 20px;}
    .containerx{margin-right: -19px;
        margin-left: -5px;}    
     
    .neoxacord .MuiAccordionDetails-root { 
        display: block;
        padding: 8px 16px 16px;
        margin-right: 0px;
        margin-left: 0px;
    }
    .neoxacord .MuiAccordionDetails-root .container{margin-right: -15px;
        margin-left: -25px; padding-right: 0px;}
    
    .neoxacord .MuiAccordionDetails-root .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 110%;
    } 

    
    
    button{margin:5px;}
    .neox50 {
        flex: 0 0 100%;
        /* max-width: 50%; */
        width: 100%;
        display: inline-block; 
    }
    .neoinline70 {
        flex: 0 0 70%;
        /* max-width: 50%; */
        width: 70%;
        display: inline-block; 
    }
    .neoinline40 {
        flex: 0 0 40%;
        /* max-width: 50%; */
        width: 40%;
        display: inline-block; 
    }
    .neoinline30 {
        flex: 0 0 30%;
        /* max-width: 50%; */
        width: 30%;
        display: inline-block; 
    }
    .neoinline20 {
        flex: 0 0 20%;
        /* max-width: 50%; */
        width: 20%;
        display: inline-block; 
    }
    .neoinline50l {
        flex: 0 0 50%;
        /* max-width: 50%; */
        width: 50%;
        /* background-color: #5383b8; */
        display: inline-block; 
        padding-left: 20px;
    }

    .iamneombzin{
        margin-top: -24px;
        width: 117%;
        margin-left: -23px;
        margin-right: -2px;
    }

    .iamneombzin .MuiTabs-root {
        display: flex;
        overflow: hidden;
        min-height: 18px;
        -webkit-overflow-scrolling: touch;
        max-height: 38px;  
    }

    .iamneombzin .containerx{margin-right: -29px;
        margin-left: -35px;}  
    .iamneombzin .containerxgf{margin-right: -49px;
        margin-left: 5px;}  
    /*.iamneombzin .neospx{margin-right: -20px;
        margin-left: -25px;} */
    .iamneombzin .neospxdoc{margin-right: -18px;
            margin-left: -25px;}     
    .iamneombzin .neospxtrans{margin-right: -20px;
                margin-left: -20px;}   
    .iamneombzin .neospxpay{margin-right: -20px;
                    margin-left: -20px;}           
    .iamneombz .neospxSum{margin-right: 0px;
                        margin-left: -20px;} 


    .iamneombzin .MuiIconButton-edgeEnd {
            margin-right: -12px;
        } 
    .minZnd .minZnd-file {
            border: 1px solid black;
            border-radius: 3px;
            padding: 3px;
          }    
    .minZnd .minZnd-file input {
        overflow: hidden;
        width: 0;
      } 
    .iamneombzin .MuiTab-wrapper{font-size: x-small;}  
    
    .floatrtxt{ text-align: right; margin-left: 10px;  }
  .lstfilesepox { border-bottom: 1px dotted blue; height: auto; }
  .lstfilesepx{ min-height: 40px!important; }
     
}

.lstp {
    border-bottom: 1px dotted blue; height: auto;
  }
.lstptop {
    border-top: 1px dotted blue; height: auto;
  } 
.lstpsub {
     color: #343a40; margin-top: -20px; font-size:x-small; margin-bottom: 0px;
     /*|xx-small|x-small */
  }   
.lstpsub2 {
    color: #343a40; margin-top: -35px; font-size:x-small; margin-bottom: 0px;
    /*|xx-small|x-small */
 }     

 .rdv-container {
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;

 }

 .rdv-container:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.16);
  }

  .rdv-line {
    display: flex;
    align-content: center;
    gap: 1rem
  }

/* .monoAcc {}  */

.monoAcc .MuiAccordionDetails-root { 
    display: block;
    padding: 8px 16px 16px;
    margin-right: 0px;
    margin-left: 0px;
}
.monoAcc .MuiAccordionDetails-root .containerx000{margin-right: -15px;
    margin-left: -25px; padding-right: 0px; 
    /* min-width: 100%; */
}

.monoAcc .MuiAccordionDetails-root .containerx{margin-right: 0px;
    margin-left: 0px; padding-right: 0px; 
    min-width: 525px; 
}

.monoAcc .MuiAccordionDetails-root {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}


.form-control-idemQQ {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.fxmQQ  {
    border-radius: 2px;
    color: white;
    background-color: rgb(133, 131, 131); 
    margin:5px;
}
.fxm:hover  {
    border-radius: 2px; 
    background-color: rgb(243, 129, 129); 
    margin:5px;
}
.fxmac  {
    border-radius: 2px; 
    background-color: rgb(28, 150, 12); 
    margin:5px;
}
.rndtag {
    border-radius: 2px;
    color: white;
    background-color: rgb(133, 131, 131);
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    font-weight: bold;
    padding-right: 6px; 
    line-height: 80%;
    margin:5px;
}

.minP{padding:0px;margin-top: -10px; }
/* .xbtnsm{ line-height: 1;margin-top: -10px; } */
.xbtnsm{ line-height: 1; }
.dvblkx{ 
    margin: 0px;
    /* background-color: rgb(189, 206, 231); */
    background-color: rgb(189, 206, 231);
    border: 1px solid transparent;
    width: 50%;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
 }
 /* .btn-group-xs > .btn, .btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
} */
.btn-group-xs > .btn, .btn-xs {
    padding: 0px;
    font-size: 8px;
    line-height: 0.5;
    border-radius: 3px;
}

/*****************************bootstrap style**********************/
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.panel-body {
    padding: 15px;
}
.panel-default {
    border-color: #ddd;
}
.panel-info {
    border-color: #bce8f1;
}
.panel-primary {
    border-color: #337ab7;
}
.panel-success {
    border-color: #d6e9c6;
}
.panel-warning {
    border-color: #faebcc;
}
.panel-danger {
    border-color: #ebccd1;
}


.panel-group .panel-heading {
    border-bottom: 0;
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}

.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}
.panel-primary>.panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
}
.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}
.panel-info>.panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.panel-warning>.panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.panel-danger>.panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

/******************************************************************/

.well-panel-info {
    padding: 5px;  margin: 5px;
    margin-bottom: 20px;
    background-color: #bce8f1;
    border: 1px solid transparent;
	border-color: #bce8f1;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.well-panel-info2 {
    padding: 5px; margin: 5px;
    margin-bottom: 20px;
    background-color: #bce8f1;
    border: 1px solid transparent;
	border-color: #046579;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.well-panel-info3 {
    padding: 5px; margin: 5px;
    margin-bottom: 20px;
    background-color: #e9f7fa;
    border: 1px solid transparent;
	border-color: #bce8f1;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.form-rowhard {
    display: block; 
    margin: 5px;
    /* margin-right: 5px;
    margin-left: -5px; */
    margin-top: 15px;
}
.fk{ width:inherit; }
/* .lstp lstpdv xbtnsm lstpsub */




.rowmbtm{margin-bottom: 10px;}
.zendcomment{
  /* margin-bottom: 20px; */
  margin: 10px;
  /* border-top: 1px solid black; */
    border-collapse: collapse;padding-top: 10px;margin-bottom: 30px;}
.pvcomment {
        background-color: #FFF6D9;
        border: 1px solid #EFDAA3;
        padding: 12px 16px 14px;
        margin-bottom: 16px;
        border-radius: 3px;
        color: #2F3941;
}
.unotetitleBlck{
    background-color: #EFDAA3;
    border: 1px solid #EFDAA3;
    /* padding: 12px 16px 14px; */
    padding: 12px 16px 14px;
    /* margin-bottom: 16px; */
    margin-bottom: -5px;
    border-radius: 3px;
    width: 40%;
    /* color: #2F3941; */
}

/* .rich_text .comment_input:not(.is-public) .zendesk-editor--rich-text-container {
  background: #FFF6D9;
  border: 1px solid #EFDAA3;
} */
.rich_textTA {
  background: #FFF6D9!important;
  border: 1px solid #EFDAA3!important;
}
.zendesk-editor--rich-text-container {
  box-sizing: border-box;
  position: relative;
  overflow: auto;
  border: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: text;
}
.usernote-view{float: right;margin-top: -20px;margin-bottom: 20px;}


